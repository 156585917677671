import React from "react";
import PropTypes from "prop-types";

export default class ItemTmp extends React.PureComponent {
	static propTypes = {
		text: PropTypes.string.isRequired,
		extraClass: PropTypes.string
	};

	render() {
		return (
			<div className={"comment-item clearfix tmp-item " + this.props.extraClass}>
				<div className="comment-header">
					<div className="comment-user">
						<a className="comment-avatar-placeholder animated-text-placeholder" />
						<div className="comment-header-desc animated-text-placeholder" />
					</div>
				</div>
				<p>{this.props.text}</p>
			</div>
		);
	}
}
