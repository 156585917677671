import * as types from "../constants/Constants";

export const setCommentsList = comment => ({
	type: types.NEW_COMMENT_UPDATED,
	comment
});

export const setCommentsListTmp = comment => ({
	type: types.NEW_COMMENT_ADDED,
	comment
});

export const removeCommentsListTmp = () => ({
	type: types.NEW_COMMENT_REMOVE
});

export const banUser = text => ({
	type: types.NEW_COMMENT_BAN,
	text
});

export const sortDateAdded = () => ({
	type: types.SORT_DATE_ADDED
});

export const sortMostPopular = () => ({
	type: types.SORT_MOST_POPULAR
});

export const handleSubmit = (commentText, imageId) => dispatch => {
	dispatch(setCommentsListTmp(commentText));
	$.ajax({
		url: types.URL.ADD,
		dataType: "json",
		type: "POST",
		data: {
			commentText,
			imageId,
			addCommentSubmitCheck: $("#comments-token").attr("data-id")
		},
		success: response => {
			if (response.status) {
				dispatch(setCommentsList(response.data.comments));
				$(".comments-count").text(parseInt($(".comments-count").text(), 10) + 1);
			} else {
				swal.fire({
					icon: "error",
					title: "Error!",
					text: response.data.errors.formError,
				})
				dispatch(removeCommentsListTmp());
				if (response.data.errors.formErrorPermanent) {
					dispatch(banUser(response.data.errors.formError));
				}
			}
		}
	});
};
