import React, { Component } from "react";
import PropTypes from "prop-types";
import Item from "./Item.jsx";
import ItemTmp from "./ItemTmp.jsx";

/* global LangJs */
export default class List extends Component {
	static propTypes = {
		comments: PropTypes.array.isRequired,
		tokenLike: PropTypes.string.isRequired,
		actions: PropTypes.object.isRequired,
		stateStore: PropTypes.object.isRequired
	};

	constructor(props) {
		super(props);
		this.showMore = this.showMore.bind(this);
		this.handleScroll = this.handleScroll.bind(this);
	}

	state = {
		interval: 0,
		allLoaded: false,
		page: 2
	};

	componentDidMount() {
		if (document.querySelector("#commentsButton")) {
			document.querySelector("#commentsButton").addEventListener("click", () => {
				this.props.actions.loadInitComments();
				this.state.interval = setInterval(() => {
					if (!document.hidden && this.props.stateStore.sorting === 0) {
						this.props.actions.loadNewComments(this.props.stateStore.lastCommentId);
					}
				}, 15000);
			});
		} else {
			this.props.actions.loadInitComments();
			this.state.interval = setInterval(() => {
				if (!document.hidden && this.props.stateStore.sorting === 0) {
					this.props.actions.loadNewComments(this.props.stateStore.lastCommentId);
				}
			}, 15000);
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.stateStore.sorting !== this.props.stateStore.sorting) {
			this.props.actions.setCommentsList([]);
			this.props.actions.loadInitComments();
			this.setState({ allLoaded: false, page: 2 });
			$(".comments-list").scrollTop(0);
		}
	}

	componentWillUnmount() {
		clearInterval(this.state.interval);
	}

	showMore() {
		this.props.actions.loadMoreComments(this.state.page);
		this.setState({ page: this.state.page + 1 });
		if (this.props.stateStore.loadLastPage) {
			this.setState({ allLoaded: true });
		}
	}

	handleScroll(event) {
		var $this = $(event.target);
		var scrollHeight = $this.prop("scrollHeight");
		var scrollTop = $this.scrollTop() + $this.height();
		console.log("Scrolling....");
		console.log("scrollTop", Math.round(scrollTop));
		console.log("scrollHeight", Math.round(scrollHeight));
		console.log("All loaded", this.state.allLoaded);
		console.log("Is loading?", this.props.stateStore.isLoading);
		if (
			Math.round(scrollTop) >= Math.round(scrollHeight) &&
			!this.state.allLoaded &&
			!this.props.stateStore.isLoading
		) {
			this.showMore();
		}
	}

	renderLoadMore = () => {
		if (this.props.stateStore.isLoading || this.props.comments.length < 50 || this.state.allLoaded) {
			return null;
		}
		return <div className="default-padding text-center" />;
	};

	render() {
		if (!this.props.stateStore.firstLoadDone) {
			return (
				<div className="loading-screen">
					<i className="fa fa-refresh fa-spin" />
				</div>
			);
		}

		if (this.props.comments.length === 0) {
			return <div className="text-center">{LangJs.comments.noContent}</div>;
		}
		const items = [];
		this.props.comments.forEach(value => {
			if (value.tmp) {
				items.push(<ItemTmp text={value.text} key="key-tmp" />);
			} else {
				const key = `${value.id}-item`;
				items.push(
					<Item
						item={value}
						key={key}
						actions={this.props.actions}
						force={this.forceLoad}
						tokenComment={this.props.stateStore.tokenComment}
						tokenLike={this.props.stateStore.tokenLike}
					/>
				);
			}
		});

		return (
			<div className="comments-list" onScroll={this.handleScroll}>
				{items}
				{this.props.stateStore.isLoading && (
					<div className="loading-screen">
						<i className="fa fa-refresh fa-spin" />
					</div>
				)}
				{this.renderLoadMore()}
			</div>
		);
	}
}
