import {
	NEW_COMMENT_ADDED,
	NEW_COMMENT_REPLY_ADDED,
	NEW_COMMENT_UPDATED,
	NEW_COMMENT_REPLY_UPDATED,
	INIT_LOAD,
	LOADING_START,
	LOADING_END,
	LOAD_LAST_PAGE,
	LAST_COMMENT_ID,
	NEW_COMMENT_BAN,
	SORT_DATE_ADDED,
	SORT_MOST_POPULAR,
	RESET_COMMENTS,
	SET_TOKEN_COMMENT,
	SET_TOKEN_LIKE
} from "../constants/Constants";

/* global LangJs */
const initialState = {
	textAreaDisabled: false,
	firstLoadDone: false,
	lastCommentId: 0,
	placeholder: LangJs.comments.placeholder,
	sorting: localStorage.getItem("comment_sort") === "rating" ? 1 : 0,
	isLoading: false,
	loadLastPage: false,
	tokenComment: null,
	tokenLike: null
};

export default function stateStore(state = initialState, action) {
	switch (action.type) {
		case NEW_COMMENT_ADDED:
			return Object.assign({}, state, {
				textAreaDisabled: true
			});

		case NEW_COMMENT_UPDATED:
			return Object.assign({}, state, {
				textAreaDisabled: false,
				lastCommentId: action.comment.id
			});

		case NEW_COMMENT_REPLY_ADDED:
			return Object.assign({}, state, {
				textAreaDisabled: true
			});

		case NEW_COMMENT_REPLY_UPDATED:
			return Object.assign({}, state, {
				textAreaDisabled: false
			});

		case INIT_LOAD:
			return Object.assign({}, state, {
				firstLoadDone: true
			});

		case RESET_COMMENTS:
			return Object.assign({}, state, {
				firstLoadDone: false
			});

		case LOADING_START:
			return Object.assign({}, state, {
				isLoading: true
			});

		case LOADING_END:
			return Object.assign({}, state, {
				isLoading: false
			});

		case LOAD_LAST_PAGE:
			return Object.assign({}, state, {
				loadLastPage: true
			});

		case LAST_COMMENT_ID:
			return Object.assign({}, state, {
				lastCommentId: action.comment.id
			});

		case NEW_COMMENT_BAN:
			return Object.assign({}, state, {
				placeholder: action.text
			});

		case SORT_DATE_ADDED:
			return Object.assign({}, state, {
				sorting: 0,
				loadLastPage: false
			});

		case SORT_MOST_POPULAR:
			return Object.assign({}, state, {
				sorting: 1,
				loadLastPage: false
			});

		case SET_TOKEN_COMMENT:
			return Object.assign({}, state, {
				tokenComment: action.tokenComment
			});

		case SET_TOKEN_LIKE:
			return Object.assign({}, state, {
				tokenLike: action.tokenLike
			});
		default:
			return state;
	}
}
