import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from './Dropdown.jsx';

/* global LangJs */
export default class HeaderLogin extends React.PureComponent {

	static propTypes = {
		actions: PropTypes.object.isRequired,
		stateStore: PropTypes.object.isRequired,
	};

	render() {
		return (
			<div id="comments-footer" className="text-center comments-form default-padding">
				<p dangerouslySetInnerHTML={{ __html: LangJs.comments.loginText }} />
				<Dropdown actions={this.props.actions} active={this.props.stateStore.sorting} />
			</div>
		);
	}
}
