import { combineReducers } from 'redux';
import comments            from './comments';
import stateStore          from './stateStore';


const rootReducer = combineReducers({
	comments,
	stateStore,
});

export default rootReducer;
