import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import Dropdown from "./Dropdown.jsx";

/* global LangJs swal */
export default class Header extends React.Component {
	static propTypes = {
		actions: PropTypes.object.isRequired,
		stateStore: PropTypes.object.isRequired
	};

	state = {
		uploading: false,
		imageSrc: "",
		imageId: 0
	};

	handleSubmit = event => {
		event.preventDefault();
		if (this.state.uploading) return;
		const text = ReactDOM.findDOMNode(this.refs.comment).value.trim();
		if (text.length > 0) {
			ReactDOM.findDOMNode(this.refs.comment).value = "";
			this.props.actions.handleSubmit(text, this.state.imageId);
			this.setState({
				imageSrc: "",
				imageId: 0
			});
		}
	};

	handleKeyUp = event => {
		if (event.key === "Enter") {
			this.handleSubmit(event);
		}
	};

	handleKeyDown = event => {
		this.setState({
			text: event.target.value
		});
	};

	handleUploadFileSelect = () => {
		$("#comments-uploader").trigger("click");
	};

	handleUploadFile = event => {
		if (event.target.files.length > 0) {
			const fileInput = event.target;
			const formData = new FormData();
			formData.append("uploadFile", fileInput.files[0]);
			formData.append("addImageSubmitCheck", "FORM_SENDED");
			this.setState({ uploading: true });
			$.ajax({
				url: `${window.location.pathname}?do=commentsAddImage`,
				type: "POST",
				data: formData,
				processData: false,
				contentType: false,
				xhrFields: {
					withCredentials: true
				},
				success: response => {
					if (response.status) {
						// this.showSuccess();
						this.setState({
							uploading: false,
							imageSrc: response.data.thumb,
							imageId: response.data.imageId
						});
					} else {
						swal("File upload fail!", response.data.error, "error").catch(swal.noop);
						this.setState({ uploading: false });
					}
					$("#comments-uploader").val("");
				},
				error: () => {
					swal("File upload fail!", "", "error").catch(swal.noop);
					this.setState({ uploading: false });
					$("#comments-uploader").val("");
				}
			});
		}
	};

	removeImage = () => {
		this.setState({
			imageSrc: "",
			imageId: 0
		});
	};

	renderFileInputIcon = () => {
		if (this.state.uploading) {
			return <i className="fa fa-refresh fa-spin comment-form-upload" />;
		}
		if (this.state.imageId !== 0) {
			return (
				<div className="comment-image-preview">
					<i className="fa fa-times" onClick={this.removeImage} />
					<img src={this.state.imageSrc} />
				</div>
			);
		}
		return <i className="fa fa-camera comment-form-upload" onClick={this.handleUploadFileSelect} />;
	};

	render() {
		return (
			<form className="header-form text-left default-padding relative" onSubmit={this.handleSubmit}>
				<textarea
					name="comment"
					className="comment-form"
					ref="comment"
					placeholder={this.props.stateStore.placeholder}
					onKeyUp={this.handleKeyUp}
					onKeyDown={this.handleKeyDown}
					disabled={this.props.stateStore.textAreaDisabled}
				/>
				{this.renderFileInputIcon()}
				<input
					type="file"
					id="comments-uploader"
					style={{ display: "none" }}
					onChange={this.handleUploadFile}
					accept="image/*"
				/>
				<button type="submit" className="comment-form-btn" disabled={this.state.uploading}>
					<i className="fa fa-paper-plane-o" />
				</button>
				<Dropdown actions={this.props.actions} active={this.props.stateStore.sorting} />
			</form>
		);
	}
}
