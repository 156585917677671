import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Autolinker from "autolinker";

import ItemActions from "./ItemActions.jsx";
import ItemTmp from "./ItemTmp.jsx";
import Reply from "./Reply.jsx";
import ReplyItem from "./ReplyItem.jsx";
import Rating from "./Rating.jsx";

/* global LangJs Modal */
export default class Item extends React.Component {
	static propTypes = {
		item: PropTypes.object.isRequired,
		actions: PropTypes.object.isRequired,
		force: PropTypes.func.isRequired
	};

	state = {
		replyForm: 0,
		replyTo: null,
		showReplies: false
	};

	getCommentText(comment) {
		let text = comment;
		if (text !== "" && typeof text !== "undefined") {
			try {
				text = window.atob(text);
			} finally {
				text = decodeURIComponent(decodeURIComponent(escape(text)));

				// seznam našich domén
				const ourDomains = [
					"ashemaletube.com",
					"boyfriendtv.com",
					"pornoxo.com",
					"fetishpapa.com",
					"amagay.com",
					"ast.tv",
					"tranny.vip",
					"chicks.vip",
					"transen.xyz"
				];

				const ourSubdomains = ["", "www.", "m.", "forum."];

				// najdi linky
				text = Autolinker.link(text, {
					className: "linkified",
					replaceFn: function(match) {
						if (match.getMatchedText().startsWith("//")) {
							return false;
						}
						let matchHref = new URL(match.getAnchorHref());
						let isOurDomain = false;

						// pokud link obsahuje text naší domény
						ourDomains.forEach(domain => {
							ourSubdomains.forEach(subdomain => {
								if (matchHref.hostname == subdomain + domain) {
									isOurDomain = true;
								}
							});
						});

						// prolinkuj
						if (isOurDomain) {
							return true;
						}

						return false;
					}
				});
			}
		}
		return text;
	}

	getCreated(time) {
		const timestamp = time * 1000;
		const date = new Date(timestamp);
		let fullTime = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} `;
		fullTime += `${date.getHours()}:${date.getMinutes()}`;
		return {
			timestamp,
			timeago: $.fn.timeago.get(timestamp),
			full: fullTime
		};
	}

	showFullImg = src => {
		const modal = new Modal("full-width");
		modal.setHeader("Comment Image");
		modal.setContent(`<img src="${src}" class="comment-image-modal" />`);
		modal.show();
	};

	renderImage = comment => {
		if (!comment.image) return null;
		return (
			<div className="comment-image" onClick={() => this.showFullImg(comment.image.orig)}>
				<img src={comment.image.thumb} alt="Comment Image" />
			</div>
		);
	};

	handleReply = (e, user = { userId: this.props.item.userId, userName: this.props.item.userName, dontTag: true }) => {
		e.preventDefault();
		loadUserIcons();
		this.setState({
			replyForm: 1,
			replyTo: user,
			showReplies: true
		});
	};

	handleForm = () => {
		this.setState({
			replyForm: 0,
			replyTo: null
		});
	};

	handleShowReplies = e => {
		e.preventDefault();
		if (this.state.showReplies) {
			this.setState({
				showReplies: false
			});
		} else {
			this.setState({
				showReplies: true
			});
		}
	};

	rollReplies = () => {
		if (this.props.item.children.length < 5) {
			this.setState({
				showReplies: false
			});
		} else {
			this.setState({
				showReplies: true
			});
		}
	};

	render() {
		if (typeof this.props.item === "undefined") {
			return null;
		}
		const comment = this.props.item;

		var userNameClass;
		userNameClass = "comment-name " + comment.userClass;

		const userNameCss = classNames(userNameClass, {
			"text-danger": comment.userAdmin
		});
		const created = this.getCreated(comment.created);
		const profileUrl = `/profiles/${comment.userId}/`;
		const commentText = this.getCommentText(comment.comment);

		const userImgStyle = { backgroundImage: `url(${comment.userImg})` };

		const dateCreated = new Date(created["full"]);
		const dateFormatted =
			dateCreated.getFullYear() +
			"-" +
			("0" + (dateCreated.getMonth() + 1)).slice(-2) +
			"-" +
			("0" + dateCreated.getDate()).slice(-2) +
			" " +
			(dateCreated.getHours() < 10 ? "0" : "") +
			dateCreated.getHours() +
			":" +
			(dateCreated.getMinutes() < 10 ? "0" : "") +
			dateCreated.getMinutes();

		const reportIcon = classNames("fa", {
			"fa-flag": comment.userReporting !== "process",
			"fa-refresh fa-spin": comment.userReporting === "process"
		});

		const replyForm = () => {
			return this.state.replyForm === 1 ? (
				<Reply
					parentId={this.props.item.id}
					replyTo={this.state.replyTo}
					actions={this.props.actions}
					toggleForm={this.handleForm}
				/>
			) : (
				""
			);
		};
		const replies = [];
		if (this.props.item.children.length > 0) {
			this.props.item.children.forEach(value => {
				if (value.tmp) {
					replies.push(<ItemTmp extraClass="reply" text={value.text} key="key-tmp" />);
				} else {
					replies.push(
						<ReplyItem
							item={value}
							actions={this.props.actions}
							tokenComment={this.props.tokenComment}
							tokenLike={this.props.tokenLike}
							handleReply={this.handleReply}
						/>
					);
				}
			});
		}

		const renderReplies = () => {
			if (replies.length > 0) {
				if (this.state.showReplies === true) {
					return (
						<div className="replies">
							<a href="#" className="js-load-user-icons" onClick={this.handleShowReplies}>
								<i className="fa fa-angle-up" /> {LangJs.comments.hideReplies}
							</a>
							{replies.reverse()}
						</div>
					);
				} else {
					return (
						<div className="replies">
							<a href="#" className="js-load-user-icons" onClick={this.handleShowReplies}>
								<i className="fa fa-angle-down" /> {LangJs.comments.showReplies} ({replies.length})
							</a>
						</div>
					);
				}
			}
		};

		const renderReplyBtn = () => {
			return Userdata.id ? (
				<a href="" className="comment-reply-btn" onClick={this.handleReply}>
					Reply
				</a>
			) : (
				""
			);
		};

		return (
			<div className="comment-item clearfix">
				<div className="comment-header">
					<div className="comment-user">
						<a
							className="comment-avatar"
							title={comment.userName}
							target="_blank"
							href={profileUrl}
							style={userImgStyle}
						/>
						<div className="comment-header-desc">
							<a className={userNameCss} target="_blank" title={comment.userName} href={profileUrl}>
								{comment.userName}
							</a>
							<p className="time comment-time_ago" data-timeago={created.timestamp} title={dateFormatted}>
								{created.timeago}
							</p>
						</div>
					</div>
					<ItemActions
						item={comment}
						actions={this.props.actions}
						handleReply={this.handleReply}
						isMenu={true}
						tokenComment={this.props.tokenComment}
						tokenLike={this.props.tokenLike}
					/>
				</div>

				<p>
					{this.renderImage(comment)}
					<span dangerouslySetInnerHTML={{ __html: commentText }} />
				</p>
				<div className="comment-actions">
					<Rating item={comment} actions={this.props.actions} token={this.props.tokenLike} />
					{renderReplyBtn()}
				</div>
				{renderReplies()}
				{replyForm()}
			</div>
		);
	}
}
