import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

export default class Dropdown extends Component {

	static propTypes = {
		actions: PropTypes.object.isRequired,
		active: PropTypes.number,
	};

	state = {
		visible: false,
	};

	componentDidMount() {
		window.addEventListener('mouseup', this.handleWindowClick.bind(this), false);
	}

	handleWindowClick = (event) => {
		if (!ReactDOM.findDOMNode(this).contains(event.target)) {
			this.setState({
				visible: false,
			});
		}
	};

	handleDateAdded = () => {
		this.props.actions.sortDateAdded();
		this.handleToggleDropdown();
	};

	handlePopular = () => {
		this.props.actions.sortMostPopular();
		this.handleToggleDropdown();
	};

	handleToggleDropdown = () => {
		this.setState({
			visible: !this.state.visible,
		});
	};

	render() {
		let clsName = 'comment-dropdown';
		let cogClass = 'fa fa-cog comments-cog';
		if (this.state.visible) {
			clsName += ' collapsed';
			cogClass += ' text-active';
		}
		const class1 = this.props.active === 0 ? 'active' : '';
		const class2 = this.props.active === 1 ? 'active' : '';

		return (
			<div className="comment-sorting-dropdown text-left">
				<i className={cogClass} onClick={this.handleToggleDropdown} />
				<ul className={clsName} >
					<li onClick={this.handleDateAdded} >
						<a className={class1}>Date added</a>
					</li>
					<li onClick={this.handlePopular} >
						<a className={class2}>Most Popular</a>
					</li>
				</ul>
			</div>
		);
	}
}
