import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

/* global LangJs Userdata */
export default class Rating extends React.Component {
	static propTypes = {
		item: PropTypes.object.isRequired,
		actions: PropTypes.object.isRequired
	};

	handleRateUp = () => {
		if (this.props.item.userRating === "process_1") {
			return;
		}
		const id = this.props.item.id;
		this.props.actions.rateUp(id, this.props.token);
	};

	handleRateDown = () => {
		if (this.props.item.userRating === "process_-1") {
			return;
		}
		const id = this.props.item.id;
		this.props.actions.rateDown(id, this.props.token);
	};

	render() {
		const comment = this.props.item;

		const rateCss = classNames("comment-rating", {
			"comment-positive": comment.rating >= 0,
			"text-danger": comment.rating < 0
		});
		const rateUpCss = classNames("thumbs-up", {
			active: comment.userRating === 1
		});
		const rateDownCss = classNames("thumbs-down", {
			active: comment.userRating === -1
		});
		const rateUpIcon = classNames("fa", {
			"fa-thumbs-up": comment.userRating !== "process_1",
			"comment-positive": comment.userRating == 1,
			"fa-refresh fa-spin": comment.userRating === "process_1"
		});
		const rateDownIcon = classNames("fa", {
			"fa-thumbs-down": comment.userRating !== "process_-1",
			"text-danger": comment.userRating == -1,
			"fa-refresh fa-spin": comment.userRating === "process_-1"
		});

		return (
			<div className="rating-sm btn-group btn-group-sm" role="group">
				<div role="group">
					<a className={rateUpCss} onClick={this.handleRateUp}>
						<span className={rateUpIcon} />
					</a>
					<a className={rateDownCss} onClick={this.handleRateDown}>
						<span className={rateDownIcon} />
					</a>
					<a className={rateCss}>{comment.rating}</a>
				</div>
			</div>
		);
	}
}
