import React from "react";
import PropTypes from "prop-types"
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ListTemplate from "../components/List.jsx";
import * as ListActions from "../actions/ListActions";

const List = ({ comments, tokenLike, stateStore, actions }) => {
	return <ListTemplate comments={comments} tokenLike={tokenLike} stateStore={stateStore} actions={actions} />;
};

List.propTypes = {
	comments: PropTypes.array.isRequired,
	tokenLike: PropTypes.string.isRequired,
	stateStore: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired
};

const mapStateToProps = state => {
	return {
		comments: state.comments,
		tokenLike: state.tokenLike,
		stateStore: state.stateStore
	};
};

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(ListActions, dispatch)
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(List);
