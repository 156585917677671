export const LOAD_COMMENTS = "LOAD_COMMENTS";
export const NEW_COMMENT_ADDED = "NEW_COMMENT_ADDED";
export const NEW_COMMENT_REPLY_ADDED = "NEW_COMMENT_REPLY_ADDED";
export const NEW_COMMENT_UPDATED = "NEW_COMMENT_UPDATED";
export const NEW_COMMENT_REPLY_UPDATED = "NEW_COMMENT_REPLY_UPDATED";
export const INIT_LOAD = "INIT_LOAD";
export const LOADING_START = "LOADING_START";
export const LOADING_END = "LOADING_END";
export const LOAD_LAST_PAGE = "LOAD_LAST_PAGE";
export const ITEM_RATING_PROCESS = "ITEM_RATING_PROCESS";
export const ITEM_REPORT_PROCESS = "ITEM_REPORT_PROCESS";
export const ITEM_RATING_DONE = "ITEM_RATING_DONE";
export const ITEM_REPORT_DONE = "ITEM_REPORT_DONE";
export const ITEM_DELETE_PROCESS = "ITEM_DELETE_PROCESS";
export const ITEM_DELETE_DONE = "ITEM_DELETE_DONE";
export const ITEM_DELETE_FAIL = "ITEM_DELETE_FAIL";
export const SORT_DATE_ADDED = "SORT_DATE_ADDED";
export const SORT_MOST_POPULAR = "SORT_MOST_POPULAR";
export const LAST_COMMENT_ID = "LAST_COMMENT_ID";
export const OLDEST_COMMENT_ID = "OLDEST_COMMENT_ID";
export const NEW_COMMENT_REMOVE = "NEW_COMMENT_REMOVE";
export const NEW_COMMENT_BAN = "NEW_COMMENT_BAN";
export const RESET_COMMENTS = "RESET_COMMENTS";
export const LOAD_MORE_COMMENTS = "LOAD_MORE_COMMENTS";
export const SET_TOKEN_COMMENT = "SET_TOKEN_COMMENT";
export const SET_TOKEN_LIKE = "SET_TOKEN_LIKE";

export const LOAD_LIMIT = 50;

//* Custom *//
export const URL = {
	LOAD: `${window.location.pathname}?do=commentsLoad`,
	ADD: `${window.location.pathname}?do=commentsAdd`,
	LIKE: `${window.location.pathname}?do=commentsLike&`,
	DELETE: `${window.location.pathname}?do=commentsDelete&`,
	REPORT: `${window.location.pathname}?do=commentsReport&`
};
