import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import * as types from "../constants/Constants";

export default class Reply extends React.Component {
	static propTypes = {
		parentId: PropTypes.string.isRequired,
		toggleForm: PropTypes.func.isRequired,
		actions: PropTypes.object.isRequired,
		replyTo: PropTypes.object.isRequired
	};

	state = {
		msg: ""
	};

	componentDidUpdate(oldProps) {
		this.nameInput.focus();
		const newProps = this.props;
		if (!this.props.replyTo.dontTag) {
			if (oldProps.replyTo !== newProps.replyTo) {
				this.setState({ msg: `@${this.props.replyTo.userName} ` });
			}
		} else {
			if (oldProps.replyTo !== newProps.replyTo) {
				this.setState({ msg: "" });
			}
		}
	}

	componentDidMount() {
		this.nameInput.value = "";
		this.nameInput.value = this.state.msg;
		if (!this.props.replyTo.dontTag) {
			this.setState({
				msg: `@${this.props.replyTo.userName} `
			});
		} else {
			this.setState({
				msg: ""
			});
		}
	}

	handleSubmit = e => {
		if (typeof e !== "undefined") {
			e.preventDefault();
		}
		let commentText = this.state.msg;
		let parentId = this.props.parentId;

		if (commentText.length > 0) {
			delete this.props.replyTo.dontTag;
			this.props.actions.handleSubmit(commentText, parentId, this.props.replyTo);
		}
		this.props.toggleForm();
	};

	handleKeyUp = e => {
		if (e.key === "Enter") {
			this.handleSubmit();
		}
	};

	handleChange = e => {
		this.setState({
			msg: e.target.value
		});
	};

	render() {
		return (
			<div className="comment-reply">
				<form action="" onSubmit={this.handleSubmit} onKeyUp={this.handleKeyUp}>
					<textarea
						name=""
						id=""
						autoFocus={true}
						value={this.state.msg}
						autofocus={true}
						className="comment-form"
						onChange={this.handleChange}
						ref={input => {
							this.nameInput = input;
						}}
					/>
					<input type="hidden" name="parentCommentId" value={this.props.parentId} />
					<button type="submit" className="comment-form-btn">
						<i className="fa fa-paper-plane-o" />
					</button>
				</form>
			</div>
		);
	}
}
