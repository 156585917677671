import React from 'react';
import PropTypes from "prop-types"
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import HeaderTemplate from '../components/Header.jsx';
import HeaderLogin from '../components/HeaderLogin.jsx';
import * as HeaderActions from '../actions/HeaderActions';

/* global Userdata */
const Header = ({ stateStore, actions }) => (
	Userdata.id ?
		<HeaderTemplate stateStore={stateStore} actions={actions} /> :
		<HeaderLogin stateStore={stateStore} actions={actions} />
);

Header.propTypes = {
	stateStore: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
	stateStore: state.stateStore,
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(HeaderActions, dispatch),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Header);
