import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import DropdownMenu from "../../ManageContent/components/MiniParts/DropdownMenu.jsx";

/* global LangJs Userdata */
export default class ItemActions extends React.Component {
	static propTypes = {
		item: PropTypes.object.isRequired,
		actions: PropTypes.object.isRequired,
		isMenu: PropTypes.bool
	};

	handleDropdown = (val, id) => {
		if (val == "delete") {
			this.handleDelete();
		} else if (val == "report") {
			this.handleReport();
		} else if (val == "reply") {
			this.handleReply();
		}
	};

	handleDelete = () => {
		if (this.props.item.deleteWait === "process") {
			return;
		}
		const id = this.props.item.id;
		this.props.actions.deleteItem(id, this.props.tokenComment);
	};

	handleReport = () => {
		const id = this.props.item.id;
		this.props.actions.reportItem(id, this.props.tokenComment);
	};

	handleReply = () => {
		this.props.handleReply();
	};

	render() {
		const comment = this.props.item;

		const itemActionsArray = [["report", LangJs.comments.report]];
		// if (Userdata.id) {
		// 	itemActionsArray.unshift(["reply", LangJs.comments.reply]);
		// }
		if (Userdata.admin || Userdata.id == this.props.item.userId || Userdata.id == this.props.item.contentOwner) {
			itemActionsArray.push(["delete", LangJs.comments.delete]);
		}

		return (
			<div className="manage-dropdowns">
				<div className="inline-block">
					<DropdownMenu items={itemActionsArray} handle={this.handleDropdown} isMenu={this.props.isMenu} />
				</div>
			</div>
		);
	}
}
