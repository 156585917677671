import * as types from "../constants/Constants";

export const sortDateAdded = () => ({
	type: types.SORT_DATE_ADDED
});

export const sortMostPopular = () => ({
	type: types.SORT_MOST_POPULAR
});

function resortComments(dispatch) {
	let sort = localStorage.getItem("comment_sort");
	if (!/(date|rating)/g.test(sort)) {
		sort = "date"; // default sorting by date
		localStorage.setItem("comment_sort", sort);
	}

	if (sort === "date") {
		dispatch(sortDateAdded());
	} else {
		dispatch(sortMostPopular());
	}
}

/* global swal Userdata LoginModal */
export const setHideInitialListLoader = () => ({
	type: types.INIT_LOAD
});

export const setHideListLoader = () => ({
	type: types.LOADING_END
});

export const setCommentsListReplyTmp = (comment, parentId, replyTo) => ({
	type: types.NEW_COMMENT_REPLY_ADDED,
	comment,
	parentId,
	replyTo
});

export const setCommentsListReply = (comment, parentId, replyTo) => ({
	type: types.NEW_COMMENT_REPLY_UPDATED,
	comment,
	parentId,
	replyTo
});

export const setVisibleListLoader = () => ({
	type: types.LOADING_START
});

export const setCommentsList = comments => ({
	type: types.LOAD_COMMENTS,
	comments
});

export const resetCommentsList = () => ({
	type: types.RESET_COMMENTS
});

export const setItemRateProgress = (id, value) => ({
	type: types.ITEM_RATING_PROCESS,
	id,
	value
});
export const setItemReportProgress = (id, value) => ({
	type: types.ITEM_REPORT_PROCESS,
	id
});

export const setItemRateDone = (id, response) => ({
	type: types.ITEM_RATING_DONE,
	id,
	rating: response.rating,
	userRating: response.userRating
});

export const setItemReportDone = id => ({
	type: types.ITEM_REPORT_DONE,
	id
});

export const setItemDeleteProgress = id => ({
	type: types.ITEM_DELETE_PROCESS,
	id
});

export const setItemDeleteDone = id => ({
	type: types.ITEM_DELETE_DONE,
	id
});

export const setItemDeleteFail = id => ({
	type: types.ITEM_DELETE_FAIL,
	id
});

export const setLastCommentId = comment => ({
	type: types.LAST_COMMENT_ID,
	comment
});

export const setLoadedLastPage = () => ({
	type: types.LOAD_LAST_PAGE
});

export const setMoreComments = comments => ({
	type: types.LOAD_MORE_COMMENTS,
	comments
});

export const setTokenComment = tokenComment => ({
	type: types.SET_TOKEN_COMMENT,
	tokenComment
});

export const setTokenLike = tokenLike => ({
	type: types.SET_TOKEN_LIKE,
	tokenLike
});

export const loadInitComments = () => dispatch => {
	dispatch(resetCommentsList());
	$.ajax({
		url: `${types.URL.LOAD}&sort=${localStorage.getItem("comment_sort")}&limit=init`,
		dataType: "json",
		success: response => {
			dispatch(setHideInitialListLoader()); // Hide loading spinner
			dispatch(setCommentsList(response.data.comments));
			if (response.data.comments.length > 0) {
				dispatch(setLastCommentId(response.data.comments[0]));
				resortComments(dispatch);
			}

			dispatch(setTokenComment(response.data.tokenComment));
			dispatch(setTokenLike(response.data.tokenLike));
		}
	});
};

var responseLength = 0;

export const loadMoreComments = page => dispatch => {
	console.log("loadMoreComments");
	dispatch(setVisibleListLoader());
	$.ajax({
		url: `${types.URL.LOAD}&sort=${localStorage.getItem("comment_sort")}&limit=${page}`,
		dataType: "json",
		success: response => {
			dispatch(setHideListLoader());
			if (response.data.comments.length <= 0) {
				dispatch(setLoadedLastPage());
			} else {
				if (responseLength == 0) {
					responseLength = response.data.comments.length;
				}
				dispatch(setMoreComments(response.data.comments));
				resortComments(dispatch);
				if (response.data.comments.length < responseLength) {
					dispatch(setLoadedLastPage());
				}
			}
			dispatch(setHideInitialListLoader());
		}
	});
};

export const loadNewComments = id => dispatch => {
	$.ajax({
		url: `${types.URL.LOAD}&commentId=${id}`,
		dataType: "json",
		success: response => {
			if (response.data.comments.length > 0) {
				dispatch(setLastCommentId(response.data.comments[0]));
				$(".comments-count").text(parseInt($(".comments-count").text(), 10) + response.data.comments.length);
				dispatch(setCommentsList(response.data.comments));
				resortComments(dispatch);
			}
		}
	});
};

export const rateDown = (id, tokenLike) => dispatch => {
	if (!Userdata.id) {
		LoginModal.show();
		return;
	}
	dispatch(setItemRateProgress(id, -1));

	const url = `${types.URL.LIKE}id=${id}&value=-1&csrf=${tokenLike}`;
	$.ajax({
		url,
		dataType: "json",
		success: response => {
			if (response.status) {
				dispatch(setItemRateDone(id, response.data));
			} else {
				swal(response.data.response).catch(swal.noop);
			}
		}
	});
};

export const rateUp = (id, tokenLike) => dispatch => {
	if (!Userdata.id) {
		LoginModal.show();
		return;
	}
	dispatch(setItemRateProgress(id, 1));

	const url = `${types.URL.LIKE}id=${id}&value=1&csrf=${tokenLike}`;
	$.ajax({
		url,
		dataType: "json",
		success: response => {
			if (response.status) {
				dispatch(setItemRateDone(id, response.data));
			} else {
				swal(response.data.response).catch(swal.noop);
			}
		}
	});
};

export const deleteItem = (id, tokenComment) => dispatch => {
	dispatch(setItemDeleteProgress(id));
	const url = `${types.URL.DELETE}id=${id}&token=${tokenComment}`;
	$.ajax({
		url,
		dataType: "json",
		success: response => {
			console.log(response);
			if (response.status) {
				swal(response.data.response).catch(swal.noop);
				dispatch(setItemDeleteDone(id));
				let currentCount = $(".comments-count").text();
				$(".comments-count").text(currentCount - 1);
			} else {
				swal(response.data.response).catch(swal.noop);
				dispatch(setItemDeleteFail(id));
			}
		}
	});
};

export const reportItem = (id, tokenComment) => dispatch => {
	if (!Userdata.id) {
		LoginModal.show();
		return;
	}
	dispatch(setItemReportProgress(id));
	const url = `${types.URL.REPORT}id=${id}&token=${tokenComment}`;
	$.ajax({
		url,
		dataType: "json",
		success: response => {
			if (response.data) {
				swal(LangJs.comments.reported);
				dispatch(setItemReportDone(id));
			} else {
				swal(LangJs.comments.alreadyReported);
				dispatch(setItemReportDone(id));
			}
		}
	});
};

export const handleSubmit = (commentText, parentCommentId, replyTo) => dispatch => {
	dispatch(setCommentsListReplyTmp(commentText, parentCommentId, replyTo));

	// let regex = new RegExp(`@${replyTo.name}`,"g");
	// let link = commentText.replace(regex, "<a href="+replyTo.url+">@"+replyTo.name+"</a>");
	$.ajax({
		url: types.URL.ADD,
		dataType: "json",
		type: "POST",
		data: {
			commentText,
			parentCommentId,
			replyUserId: replyTo.userId,
			imageId: 0,
			addCommentSubmitCheck: $("#comments-token").attr("data-id")
		},
		success: response => {
			if (response.status) {
				dispatch(setCommentsListReply(response.data.comments, parentCommentId, replyTo));
				$(".comments-count").text(parseInt($(".comments-count").text(), 10) + 1);
			} else {
				swal.fire({
					icon: "error",
					title: "Error!",
					text: response.data.errors.formError,
				});
				dispatch(removeCommentsListTmp());
				if (response.data.errors.formErrorPermanent) {
					dispatch(banUser(response.data.errors.formError));
				}
			}
		}
	});
};
