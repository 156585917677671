import {
	LOAD_COMMENTS,
	NEW_COMMENT_ADDED,
	NEW_COMMENT_REPLY_ADDED,
	NEW_COMMENT_UPDATED,
	NEW_COMMENT_REPLY_UPDATED,
	ITEM_RATING_PROCESS,
	ITEM_REPORT_PROCESS,
	ITEM_RATING_DONE,
	ITEM_REPORT_DONE,
	ITEM_DELETE_PROCESS,
	ITEM_DELETE_DONE,
	ITEM_DELETE_FAIL,
	SORT_MOST_POPULAR,
	SORT_DATE_ADDED,
	NEW_COMMENT_REMOVE,
	RESET_COMMENTS,
	LOAD_MORE_COMMENTS
} from "../constants/Constants";

const initialState = [
	// {
	// 	id: 0,
	// 	created: 0,
	// 	userId: 0,
	// 	userName: "",
	// 	userImg: "",
	// 	userAdmin: true,
	// 	comment: "",
	// 	rating: 0,
	// 	userRating: null,
	// 	contentType: 0
	// }
];

function getIndex(state, id) {
	let i = -1;

	state.forEach((val, index) => {
		if (val.id === id) {
			i = {
				x: index,
				parent: -1
			};
		} else if (val.children.length > 0) {
			val.children.forEach((valChild, indexChild) => {
				if (valChild.id == id) {
					i = {
						x: indexChild,
						parent: index
					};
				}
			});
		}
	});
	return i;
}

// function sortMostPopular(a, b) {
// 	if (a.rating > b.rating) {
// 		return -1;
// 	}
// 	if (a.rating < b.rating) {
// 		return 1;
// 	}
// 	return 0;
// }
//
// function sortDateAdded(a, b) {
// 	if (a.created > b.created) {
// 		return -1;
// 	}
// 	if (a.created < b.created) {
// 		return 1;
// 	}
// 	return 0;
// }

export default function comments(state = initialState, action) {
	switch (action.type) {
		case LOAD_MORE_COMMENTS:
			return [...state, ...action.comments];

		case LOAD_COMMENTS: {
			return [...action.comments, ...state];
		}
		case RESET_COMMENTS: {
			return [];
		}

		case NEW_COMMENT_ADDED: {
			return [
				{
					text: action.comment,
					tmp: true
				},
				...state
			];
		}

		case NEW_COMMENT_REPLY_ADDED: {
			state.forEach((val, index) => {
				if (val.id === action.parentId) {
					state[index]["children"].unshift({
						text: action.comment,
						parentId: action.parentId,
						replyTo: action.replyTo,
						tmp: true
					});
				}
			});

			return [...state];
		}

		case NEW_COMMENT_UPDATED: {
			let i = -1;
			state.forEach((val, index) => {
				if (val.tmp) {
					i = index;
				}
			});
			state[i] = action.comment;
			return [...state];
		}

		case NEW_COMMENT_REPLY_UPDATED: {
			let i = -1;
			let x = -1;
			state.forEach((val, index) => {
				if (val.id === action.parentId) {
					i = index;
					val["children"].forEach((valChild, indexChild) => {
						if (valChild.tmp) {
							x = indexChild;
						}
					});
				}
			});
			action.comment.replyTo = action.replyTo;
			state[i]["children"][x] = action.comment;
			return [...state];
		}

		case NEW_COMMENT_REMOVE: {
			let i = -1;
			state.forEach((val, index) => {
				if (val.tmp) {
					i = index;
				}
			});
			state.splice(i, 1);
			return [...state];
		}

		case ITEM_RATING_PROCESS: {
			const i = getIndex(state, action.id);
			if (i !== -1) {
				if (i.parent === -1) {
					const comment = state[i.x];
					comment.userRating = `process_${action.value}`;
					state[i.x] = comment;
				} else {
					const comment = state[i.parent].children[i.x];
					comment.userRating = `process_${action.value}`;
					state[i.parent].children[i.x] = comment;
				}
			}

			return [...state];
		}
		case ITEM_REPORT_PROCESS: {
			const i = getIndex(state, action.id);
			if (i !== -1) {
				if (i.parent === -1) {
					const comment = state[i.x];
					comment.userReporting = `process`;
					state[i.x] = comment;
				} else {
					const comment = state[i.parent].children[i.x];
					comment.userReporting = `process`;
					state[i.parent].children[i.x] = comment;
				}
			}
			return [...state];
		}

		case ITEM_RATING_DONE: {
			const i = getIndex(state, action.id);
			if (i !== -1) {
				if (i.parent === -1) {
					const comment = state[i.x];
					comment.rating = action.rating;
					comment.userRating = action.userRating;
					state[i.x] = comment;
				} else {
					const comment = state[i.parent].children[i.x];
					comment.rating = action.rating;
					comment.userRating = action.userRating;
					state[i.parent].children[i.x] = comment;
				}
			}
			return [...state];
		}
		case ITEM_REPORT_DONE: {
			const i = getIndex(state, action.id);
			if (i !== -1) {
				if (i.parent === -1) {
					const comment = state[i.x];
					comment.userReporting = "done";
					state[i.x] = comment;
				} else {
					const comment = state[i.parent].children[i.x];
					comment.userReporting = "done";
					state[i.parent].children[i.x] = comment;
				}
			}
			return [...state];
		}

		case ITEM_DELETE_PROCESS: {
			const i = getIndex(state, action.id);
			if (i !== -1) {
				if (i.parent === -1) {
					const comment = state[i.x];
					comment.deleteWait = "process";
					state[i.x] = comment;
				} else {
					const comment = state[i.parent].children[i.x];
					comment.deleteWait = "process";
					state[i.parent].children[i.x] = comment;
				}
			}
			return [...state];
		}

		case ITEM_DELETE_DONE: {
			const i = getIndex(state, action.id);
			if (i !== -1) {
				if (i.parent === -1) {
					state.splice(i.x, 1);
				} else {
					state[i.parent]["children"].splice(i.x, 1);
				}
			}
			return [...state];
		}

		case ITEM_DELETE_FAIL: {
			const i = getIndex(state, action.id);
			if (i !== -1) {
				if (i.parent === -1) {
					const comment = state[i.x];
					delete comment.deleteWait;
					state[i.x] = comment;
				} else {
					const comment = state[i.parent].children[i.x];
					delete comment.deleteWait;
					state[i.parent].children[i.x] = comment;
				}
			}
			return [...state];
		}

		case SORT_MOST_POPULAR: {
			// state.sort(sortDateAdded);
			// state.sort(sortMostPopular);
			localStorage.setItem("comment_sort", "rating");
			return state;
		}

		case SORT_DATE_ADDED: {
			// state.sort(sortDateAdded);
			localStorage.setItem("comment_sort", "date");
			return state;
		}

		default:
			return state;
	}
}
