import React from "react";
import Header from "../containers/Header";
import List from "./List";

export const Comments = () => (
	<div>
		<Header />
		<List />
	</div>
);

export default Comments;
