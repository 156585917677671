import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ItemActions from "./ItemActions.jsx";
import Rating from "./Rating.jsx";
import Autolinker from "autolinker";

export default class ReplyItem extends React.Component {
	static propTypes = {
		item: PropTypes.object.isRequired,
		actions: PropTypes.object.isRequired,
		onReply: PropTypes.func.isRequired
	};

	componentDidMount() {
		loadCommentUserIcons();
	}

	getCommentText(comment) {
		let text = comment;
		let regex = new RegExp(`@${this.props.item.replyTo.userName}`, "g");
		if (text !== "" && typeof text !== "undefined") {
			try {
				text = window.atob(text);
			} finally {
				text = decodeURIComponent(decodeURIComponent(escape(text)));
			}
		}

		const ourDomains = [
			"ashemaletube.com",
			"boyfriendtv.com",
			"pornoxo.com",
			"fetishpapa.com",
			"amagay.com",
			"ast.tv",
			"tranny.vip",
			"chicks.vip",
			"transen.xyz"
		];

		const ourSubdomains = ["", "www.", "m.", "forum."];

		// najdi linky
		text = Autolinker.link(text, {
			className: "linkified",
			replaceFn: function(match) {
				let matchHref = new URL(match.getAnchorHref());
				let isOurDomain = false;

				// pokud link obsahuje text naší domény
				ourDomains.forEach(domain => {
					ourSubdomains.forEach(subdomain => {
						if (matchHref.hostname == subdomain + domain) {
							isOurDomain = true;
						}
					});
				});

				// prolinkuj
				if (isOurDomain) {
					return true;
				}

				return false;
			}
		});

		return text.replace(
			regex,
			"<a href='/profiles/" + this.props.item.replyTo.userId + "/'>@" + this.props.item.replyTo.userName + "</a>"
		);
		return text;
	}

	getCreated(time) {
		const timestamp = time * 1000;
		const date = new Date(timestamp);
		let fullTime = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} `;
		fullTime += `${date.getHours()}:${date.getMinutes()}`;
		return {
			timestamp,
			timeago: $.fn.timeago.get(timestamp),
			full: fullTime
		};
	}

	// renderImage = comment => {
	// 	if (!comment.image) return null;
	// 	return (
	// 		<div className="comment-image" onClick={() => this.showFullImg(comment.image.orig)}>
	// 			<img src={comment.image.thumb} alt="Comment Image" />
	// 		</div>
	// 	);
	// };

	handleReport = () => {
		if (this.props.item.userReporting === "process") {
			return;
		}
		const id = this.props.item.id;
		this.props.actions.reportItem(id, this.props.tokenComment);
	};

	handleReply = e => {
		let replyUser = {
			userId: this.props.item.userId,
			userName: this.props.item.userName
		};
		this.props.handleReply(e, replyUser);
	};

	render() {
		if (typeof this.props.item === "undefined") {
			return null;
		}
		const comment = this.props.item;

		var userNameClass;
		userNameClass = "comment-name " + comment.userClass;

		const userNameCss = classNames(userNameClass, {
			"text-danger": comment.userAdmin
		});
		const created = this.getCreated(comment.created);
		const profileUrl = `/profiles/${comment.userId}/`;
		const commentText = this.getCommentText(comment.comment);
		const userImgStyle = { backgroundImage: `url(${comment.userImg})` };

		const dateCreated = new Date(created["full"]);
		const dateFormatted =
			dateCreated.getFullYear() +
			"-" +
			("0" + (dateCreated.getMonth() + 1)).slice(-2) +
			"-" +
			("0" + dateCreated.getDate()).slice(-2) +
			" " +
			(dateCreated.getHours() < 10 ? "0" : "") +
			dateCreated.getHours() +
			":" +
			(dateCreated.getMinutes() < 10 ? "0" : "") +
			dateCreated.getMinutes();

		const renderReplyBtn = () => {
			return Userdata.id ? (
				<a href="" className="comment-reply-btn" onClick={this.handleReply}>
					Reply
				</a>
			) : (
				""
			);
		};

		return (
			<div className="comment-item reply clearfix">
				<div className="comment-header reply">
					<div className="comment-user reply">
						<a
							className="comment-avatar reply"
							title={comment.userName}
							target="_blank"
							href={profileUrl}
							style={typeof userImgStyle !== "undefined" ? userImgStyle : ""}
						/>
						<div className="comment-header-desc reply">
							<a className={userNameCss} target="_blank" title={comment.userName} href={profileUrl}>
								{comment.userName}
							</a>
							<p className="time comment-time_ago" data-timeago={created.timestamp} title={dateFormatted}>
								{created.timeago}
							</p>
						</div>
					</div>
					<ItemActions
						item={comment}
						actions={this.props.actions}
						isMenu={true}
						handleReply={this.handleReply}
						tokenComment={this.props.tokenComment}
						tokenLike={this.props.tokenLike}
					/>
				</div>

				<p>
					<span dangerouslySetInnerHTML={{ __html: commentText }} />
				</p>
				<div className="comment-actions">
					<Rating item={comment} actions={this.props.actions} token={this.props.tokenLike} />
					{renderReplyBtn()}
				</div>
			</div>
		);
	}
}
