import React, { Component } from "react";
import PropTypes from "prop-types";

export default class DropdownItem extends Component {
	static propTypes = {
		handleClick: PropTypes.func.isRequired,
		value: PropTypes.number.isRequired,
		text: PropTypes.string.isRequired,
		link: PropTypes.string
	};

	handleClick = () => {
		this.props.handleClick(this.props.value);
	};

	render() {
		return (
			<li onMouseDown={this.handleClick}>
				<a href={this.props.link}>{this.props.text}</a>
			</li>
		);
	}
}
