import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import DropdownItem from "./DropdownItem.jsx";

export default class MenuButton extends Component {
	static propTypes = {
		defaultVal: PropTypes.number.isRequired,
		handle: PropTypes.func.isRequired,
		items: PropTypes.array.isRequired,
		extraClass: PropTypes.string.isRequired,
		isMenu: PropTypes.bool
	};

	state = {
		isOpen: false,
		selected: this.props.defaultVal ? parseInt(this.props.defaultVal, 10) : 0
	};

	componentDidMount() {
		document.addEventListener("click", this.handleWindowClick, false);
	}

	componentWillReceiveProps(nextProps) {
		if (typeof nextProps.reset !== "undefined") {
			if (nextProps.reset !== this.state.selected) {
				this.setState({ selected: parseInt(nextProps.reset, 10) });
			}
		}
	}

	componentWillUnmount() {
		document.removeEventListener("click", this.handleWindowClick, false);
	}

	setValue = value => {
		const val = parseInt(value, 10);
		this.props.handle(this.props.items[val][0], val);
		this.setState({
			selected: 0,
			isOpen: false
		});
	};

	close = () => {
		this.setState({ isOpen: false });
	};

	handleMouseDown = () => {
		if (!this.state.isOpen) {
			this.setState({ isOpen: true });
		} else if (this.state.isOpen && this.props.isMenu) {
			this.setState({ isOpen: false });
		}
	};

	handleWindowClick = event => {
		if (!ReactDOM.findDOMNode(this).contains(event.target)) {
			if (this.state.isOpen) {
				this.setState({ isOpen: false });
			}
		}
	};

	render() {
		const items = [];
		let baseClass = "btn-dropdown dropdown-menu" + (this.props.extraClass ? this.props.extraClass : "");
		const itemsArray = this.props.items;
		itemsArray.forEach((item, i) => {
			const key = `dropdown-${i}`;
			items.push(<DropdownItem handleClick={this.setValue} value={i} text={item[1]} key={key} />);
		});

		if (this.props.isMenu) {
			items.push(<DropdownItem handleClick={this.close} value="close" text={LangJs.base.close} />);
		}

		let menuIcon = <i className="fa fa-ellipsis-v" />;

		if (this.state.isOpen && this.props.isMenu) {
			menuIcon = <i className="fa fa-times" />;
		}

		if (this.state.isOpen) {
			baseClass += " open";
		}

		return (
			<div className={baseClass} onMouseDown={this.handleMouseDown}>
				<span className="form-control">{menuIcon}</span>
				<div>
					<ul>{items}</ul>
				</div>
			</div>
		);
	}
}
